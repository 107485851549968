import { CSSProperties, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { UIElementProps } from "types/prop-types";
import Shape from "components/ui/Shape";
import LogoJornada from "components/ui/LogoJornada";
import Eliza from "components/ui/Eliza";
import LogoOCB from "components/ui/LogoOCB";
import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";

import "./styles.scss";
import { useDialog } from "context/DialogContext";
import AudioHome from "components/audio/AudioHome";
import VolumeControl from "components/ui/VolumeControl";
import usePointsStore from "store";
import { fetchSettings } from "services/jornada.service";
import { Loading } from "pages/Loading";

const step1Bg = require("assets/images/step1-bg.png");

function AnimatedText({ children, delay }: UIElementProps) {
	return (
		<motion.div
			initial={{
				translateX: -50,
				opacity: 0,
			}}
			animate={{
				translateX: 0,
				opacity: 1,
			}}
			transition={{ duration: 1, ease: "easeOut", delay }}
		>
			{children}
		</motion.div>
	);
}

const styles: any = {
	eliza: {
		position: "absolute",
		bottom: 10,
		right: 10,
		width: "300px",
		height: "300px",
		border: "1px solid #fff",
	},
	logoJornada: {
		position: "absolute",
		top: 10,
		left: 10,
		width: "300px",
		height: "300px",
	},
};

let checkInterval: any;
const checkIntervalTime = 10000;

export function Locked() {
	const location = useLocation();
	const navigate = useNavigate();
	const { reset } = useCoop();
	const { closeDialog } = useDialog();
	const { isAuthenticated, loadingSettings, isGameEnabled } = useAuth();
	const [showForm, setShowForm] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const { resetPointsStore } = usePointsStore();

	useEffect(() => {
		// console.log(location.state);
		const locationState: any = location.state;
		if (locationState && locationState.success) {
			setShowForm(true);
		}
	}, [location]);

	useEffect(() => {
		reset();
		closeDialog();
		resetPointsStore();
	}, [reset, resetPointsStore]);

	useEffect(() => {
		console.log("opa", isGameEnabled);
		if (isGameEnabled) {
			navigate("/");
		}
	}, [isGameEnabled]);

	useEffect(() => {
		if (!checkInterval) {
			checkInterval = setInterval(async () => {
				const settings = await fetchSettings();
				if (settings?.game_enabled) {
					clearInterval(checkInterval);
					checkInterval = null;
					navigate("/");
				}
			}, checkIntervalTime);
		}
		return () => {
			clearInterval(checkInterval);
			checkInterval = null;
		};
	}, []);

	if (loadingSettings) {
		return <Loading />;
	}

	return (
		<div id="locked" className="relative w-full h-full">
			<div className="absolute top-0 left-0 bottom-0 right-0 m-auto bg-white rounded-full w-96 h-96 animate-in zoom-in flex flex-row items-center justify-center p-16">
				<LogoJornada />
				<p className="text-white absolute top-full text-4xl mt-10 font-bold">
					VOLTAREMOS JÁ
				</p>
			</div>
			<LogoOCB white className="logo-ocb" />
		</div>
	);
}
