import LogoOCB from "components/ui/LogoOCB";
import VolumeControl from "components/ui/VolumeControl";
import { useAuth } from "context/AuthContext";
import { CoopProvider, useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { CSSProperties, useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import {
	FaArrowLeft,
	FaArrowRight,
	FaHome,
	FaSignOutAlt,
	FaVolumeMute,
	FaVolumeUp,
} from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	fetchDataCoopsRegulares,
	fetchDataCredito,
	fetchDataDemais,
	fetchDataDemaisIdentidade,
	fetchDataGestao,
	fetchDataNegociosCoop,
	fetchDataSaude,
	fetchDataSouCoop,
	fetchRanking,
} from "services/jornada.service";
import Step1 from "./Step1";
import Step10 from "./Step10";
import Step11 from "./Step11";
import Step12 from "./Step12";
import Step13 from "./Step13";
import Step14 from "./Step14";
import Step15 from "./Step15";
import Step16 from "./Step16";
import Step17 from "./Step17";
import Step18 from "./Step18";
import Step19 from "./Step19";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";

import "./styles.scss";
import usePointsStore from "store";
import { set } from "date-fns";

const totalSteps = 4;

export function Step() {
	const navigate = useNavigate();
	const params = useParams();
	const { user, signOut, checkGameIsEnabled } = useAuth();
	const { closeDialog } = useDialog();
	const [score, setScore] = useState(0);
	const {
		displayPointsNotification,
		hidePointsNotification,
		showPoints,
		setDesempenhoCoop,
		setGovernancaCoop,
		setSouCoop,
		points,
		isNavigationVisible,
	} = usePointsStore();
	const {
		coopInfo,
		coop,
		negocios,
		pdgc,
		seloCoop,
		coopAtualizada,
		setCoopAtualizada,
		categoria,
		setRanking,
		setCategoria,
		setCoop,
		setNegocios,
		setPdgc,
		setCoopInfo,
	} = useCoop();

	const [step, setStep] = useState(0);

	const [loading, setLoading] = useState(false);

	// const [dadosCoop, setDadosCoop] = useState<any[] | undefined>();
	// const [coopAtualizada, setCoopAtualizada] = useState(false);
	// const [dadosPDGC, setDadosPDGC] = useState<any[] | undefined>(undefined);
	// const [dadosNegocios, setDadosNegocios] = useState<any[] | undefined>(
	// 	undefined
	// );

	function prevStep() {
		if (step < 2) {
			signOut("/");
		} else {
			navigate(`/passo/${step - 1}`, { state: { direction: "back" } });
		}
	}

	function nextStep() {
		if (step === totalSteps) return;
		navigate(`/passo/${step + 1}`, { state: { direction: "forward" } });
	}

	useEffect(() => {
		checkGameIsEnabled();
	}, [step]);

	useEffect(() => {
		closeDialog();
		hidePointsNotification();

		let partialScore = 0;

		// score
		if (step === 1) {
			if (coopAtualizada) {
				displayPointsNotification();
				setSouCoop(true);
			}
		}

		if (step === 5) {
			if (coop && coop.length > 0) {
				displayPointsNotification();
				setDesempenhoCoop(true);
			}
		}

		if (step === 8) {
			if (pdgc && pdgc.length > 0) {
				displayPointsNotification();
				setDesempenhoCoop(true);
			}
		}

		// if (step === 12) {
		// 	if (negocios && negocios.length > 0) {
		// 		partialScore = 1;
		// 	}
		// }

		// if (step === 14) {
		// 	if (seloCoop) {
		// 		partialScore = 1;
		// 	}
		// }

		setScore(partialScore);
	}, [step, coopAtualizada, seloCoop]);

	useEffect(() => {
		const displayScoreListener = (e: any) => {
			// setScore(1);
			displayPointsNotification();
		};
		addEventListener("display_score", displayScoreListener);
		const hideScoreListener = (e: any) => {
			// setScore(0);
			hidePointsNotification();
		};
		addEventListener("hide_score", hideScoreListener);
		return () => {
			removeEventListener("display_score", displayScoreListener);
			removeEventListener("hide_score", hideScoreListener);
		};
	}, []);

	useEffect(() => {
		// document.getElementById("score-display")?.className("")
	}, [score]);

	useEffect(() => {
		setStep(params.id ? parseInt(params.id) : 1);
		if (!params.id) {
			navigate("/passo/1");
		}
	}, [params]);

	useEffect(() => {
		async function loadData() {
			// console.log("aaaa",user);

			setLoading(true);

			try {
				// const resultCredito = await fetchDataCredito(user?.extra.cnpj);
				// const resultSaude = await fetchDataSaude(user?.extra.cnpj);
				// const resultDemais = await fetchDataDemais(user?.extra.cnpj);
				// const resultIdentidade = await fetchDataDemaisIdentidade(
				// 	user?.extra.cnpj
				// );
				// const negociosCredito = await fetchDataNegociosCoop(user?.extra.cnpj);
				// const resultSouCoop = await fetchDataSouCoop(user?.extra.cnpj);
				// const pdgc = await fetchDataGestao(user?.extra.cnpj);
				// const resultCoopsRegulares = await fetchDataCoopsRegulares(
				// 	user?.extra.cnpj
				// );
				// console.log("resultSouCoop", resultSouCoop);
				// console.log("resultCredito", resultCredito);
				// console.log("resultSaude", resultSaude);
				// console.log("resultDemais", resultDemais);
				// console.log("negociosCredito", negociosCredito);
				// console.log("pdgc", pdgc);
				// let desempenho: any = undefined;
				// let coopInfo: any = resultCoopsRegulares[0];
				// if (resultSouCoop.length > 0) {
				// 	setCoopAtualizada(true);
				// 	coopInfo = resultSouCoop[0];
				// }
				// console.log(coopInfo?.ramo);
				// if (coopInfo?.ramo.indexOf("CRÉDITO") > -1) {
				// 	setCategoria("credito");
				// } else if (coopInfo?.ramo.indexOf("SAÚDE") > -1) {
				// 	setCategoria("saude");
				// } else if (
				// 	coopInfo?.ramo.indexOf("AGROPECUÁRIO") > -1 ||
				// 	coopInfo?.ramo.indexOf("TRANSPORTE") > -1
				// ) {
				// 	setCategoria("demais");
				// } else if (
				// 	coopInfo?.ramo.indexOf("CONSUMO") > -1 ||
				// 	coopInfo?.ramo.indexOf("TRABALHO") > -1
				// ) {
				// 	setCategoria("identidade");
				// } else {
				// 	setCategoria("demais");
				// }
				// switch (coopInfo?.ramo) {
				// 	case "CRÉDITO":
				// 		setCategoria("credito");
				// 		break;
				// 	case "SAÚDE":
				// 		setCategoria("saude");
				// 		break;
				// 	case "AGROPECUÁRIO":
				// 	case "TRANSPORTE":
				// 		setCategoria("demais");
				// 		break;
				// 	case "CONSUMO":
				// 	case "TRABALHO, PRODUÇÃO DE BENS E SERVIÇOS":
				// 		setCategoria("identidade");
				// 	default:
				// 		setCategoria("demais");
				// 		break;
				// }
				// if (resultCredito.length > 0) {
				// 	// setCategoria("credito");
				// 	desempenho = resultCredito;
				// 	if (!coopInfo) coopInfo = resultCredito[0];
				// } else if (resultSaude.length > 0) {
				// 	// setCategoria("saude");
				// 	desempenho = resultSaude;
				// 	if (!coopInfo) coopInfo = resultSaude[0];
				// } else if (resultIdentidade.length > 0) {
				// 	// setCategoria("identidade");
				// 	desempenho = resultIdentidade;
				// 	if (!coopInfo) coopInfo = resultIdentidade[0];
				// } else if (resultDemais.length > 0) {
				// 	// setCategoria("demais");
				// 	desempenho = resultDemais;
				// 	if (!coopInfo) coopInfo = resultDemais[0];
				// }
				// if (pdgc.length > 0) {
				// 	if (!coopInfo) coopInfo = pdgc[0];
				// }
				// if (negociosCredito.length > 0) {
				// 	if (!coopInfo) coopInfo = negociosCredito[0];
				// }
				// if (resultCoopsRegulares.length > 0) {
				// 	if (!coopInfo) coopInfo = resultCoopsRegulares[0];
				// }
				// setPdgc(pdgc);
				// setNegocios(negociosCredito);
				// setCoop(desempenho);
				// setCoopInfo(coopInfo);
				// setLoading(false);

				const coopInfo = {
					...user,
					constituicao_fundacao: "2018-08-14",
				};
				setCoop(coopInfo);
				setCoopInfo(coopInfo);
				setCoopAtualizada(true);
				setLoading(false);
			} catch (err) {
				console.log(err);
			}
		}

		if (user) {
			loadData();
		}
	}, [user]);

	useEffect(() => {
		async function loadRanking() {
			try {
				const result = await fetchRanking(coopInfo.ramo);
				const position = result.findIndex(
					(item: any) => item._id === coopInfo.cnpj
				);
				const total = result[position].total;
				setRanking({ position: position + 1, total });
			} catch (e) {
				console.log(e);
			}
		}

		if (coopInfo && categoria && categoria !== "identidade") {
			loadRanking();
		}
	}, [coopInfo, categoria]);

	useEffect(() => {
		if (coop && !coopInfo) {
			const info = coop[0];
			setCoopInfo(info);
		}
	}, [coop, coopInfo]);

	useEffect(() => {
		console.log("v1.2");
	}, []);

	return (
		<div id="step-wrapper" className="relative w-full h-full">
			<div className="absolute z-20 top-10 right-10 left-10 flex flex-row items-center space-x-5">
				<div className="flex-1 flex flex-row items-center justify-between">
					{isNavigationVisible ? (
						<button
							type="button"
							className="w-20 h-20 flex items-center justify-center bg-white rounded-full"
							onClick={() => signOut("/")}
						>
							<FaHome size={36} />
						</button>
					) : null}

					<div className="flex flex-row space-x-3">
						{!!showPoints && (
							<div
								id="score-display"
								className="min-h-[60px] animate-in slide-in-from-right bg-white rounded-full flex flex-row items-center space-x-3 text-3xl px-6 pt-1 justify-between"
							>
								<span>Você ganhou</span>{" "}
								<b className="text-green-600">+ {points} pontos!</b>
							</div>
						)}

						{/* <VolumeControl /> */}
						{/* <div className="text-white ml-5">DEBUG: {category}</div> */}
					</div>
				</div>
			</div>

			{isNavigationVisible && (
				<>
					<button
						className="shadow-xl ring-2 hover:ring-4 fixed z-50 -left-3  top-0 bottom-0 my-auto w-32 h-32 flex items-center justify-center bg-white rounded-full"
						onClick={prevStep}
					>
						<FaArrowLeft size={64} />
					</button>

					{!coopInfo ||
						(step < totalSteps && (
							<button
								className="shadow-xl ring-2 hover:ring-4 fixed  z-50 -right-3 top-0 bottom-0 my-auto w-32 h-32 flex items-center justify-center bg-white rounded-full"
								onClick={nextStep}
							>
								<FaArrowRight size={64} />
							</button>
						))}
				</>
			)}

			{!loading && (
				<>
					{step === 1 && <Step1 />}
					{step === 2 && <Step2 />}
					{step === 3 && <Step8 />}
					{step === 4 && <Step18 />}
					{/* {step === 5 && <Step5 />} */}
					{/* {step === 6 && <Step6 />} */}
					{/* {step === 6 && <Step7 />} */}
					{/* {step === 7 && <Step8 />} */}
					{/* {step === 8 && <Step9 />} */}
					{/* {step === 9 && <Step10 />} */}
					{/* {step === 10 && <Step11 />} */}
					{/* {step === 10 && <Step15 />} */}
					{/* {step === 11 && <Step17 />} */}
					{/* {step === 12 && <Step18 />} */}
					{/* {step === 11 && <Step12 />} */}
					{/* {step === 13 && <Step13 />} */}
					{/* {step === 12 && <Step14 />} */}
					{/* {step === 13 && <Step15 />} */}
					{/* {step === 16 && <Step16 />} */}
					{/* {step === 14 && <Step17 />}
					{step === 15 && <Step18 />} */}
					{/* {step === 19 && <Step19 />} */}
				</>
			)}

			{loading && (
				<div className="w-full h-full flex items-center justify-center">
					<div className="text-white text-center text-4xl flex flex-row space-x-4 items-center">
						<AiOutlineLoading
							className="animated animate-spin text-orange-500"
							size={32}
						/>
						<p>Carregando a Jornada LGPDNOW...</p>
					</div>
				</div>
			)}

			<LogoOCB className="logo-ocb" />
		</div>
	);
}
