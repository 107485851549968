import { UIElementProps } from "types/prop-types";

import eliza from "assets/images/character/Picture2.png";
import home from "assets/images/character/Picture1.png";
import center from "assets/images/character/Picture1.png";
import left from "assets/images/character/Picture3.png";
import sad from "assets/images/character/Picture4.png";
import sadBig from "assets/images/eliza-sad-big.svg";
import ship from "assets/images/character/Picture1.png";
import shipHappy from "assets/images/character/Picture2.png";
import shipRight from "assets/images/eliza-ship-right.svg";
import stars from "assets/images/character/Picture1.png";

const Sources: any = {
	default: eliza,
	home,
	center,
	sad,
	sadBig,
	ship,
	shipHappy,
	shipRight,
	stars,
	left,
};

export default function Eliza({ name, className, style }: UIElementProps) {
	return (
		<img
			src={Sources[name || "default"]}
			className={`pointer-none ${className}`}
			style={style}
		/>
	);
}
