import { useCoop } from "context/CoopContext";
import { useEffect, useState } from "react";

import Eliza from "components/ui/Eliza";
import { BiCheckCircle } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";

import "./styles.scss";
import LogoJornada from "components/ui/LogoJornada";
import Shape from "components/ui/Shape";
import { useAuth } from "context/AuthContext";
import { actionLog } from "services/actionlog.service";
import AudioStep18 from "components/audio/AudioStep18";
import { useDialog } from "context/DialogContext";
import usePointsStore from "store";

const qrcode = require("assets/images/qrcode.png");
const qrcodeOcesp = require("assets/images/qrcodeOcesp.png");

export default function Step18() {
	const { coop, negocios, categoria, coopAtualizada, pdgc, seloCoop } =
		useCoop();
	const { user } = useAuth();

	const { showDialog } = useDialog();

	const [pontos, setPontos] = useState(0);

	const [sorteioActive, setSorteioActive] = useState(false);

	const {
		desempenhoCoop,
		governancaCoop,
		souCoop,
		pontosGameSomosCoop,
		pontosGameDesempenho,
		pontosGameGovernanca,
		pontosGameQuiz,
	} = usePointsStore();

	function renderQRCode() {
		if (window.location.hostname.indexOf("ocesp") > -1)
			return <img src={qrcodeOcesp} className={`pointer-none`} />;

		return <img src={qrcode} className={`pointer-none`} />;
	}

	useEffect(() => {
		let score = 0;

		if (coopAtualizada) {
			score += 1000;
		}
		if (pdgc && pdgc.length > 0) {
			score += 1000;
		}
		// if (negocios && negocios.length > 0) {
		// 	score += 1000;
		// }
		if (coop && coop.length > 0) {
			score += 1000;
		}
		// if (seloCoop) {
		// 	score += 1000;
		// }
		if (pontosGameDesempenho) {
			score += pontosGameDesempenho;
		}
		if (pontosGameGovernanca) {
			score += pontosGameGovernanca;
		}
		if (pontosGameSomosCoop) {
			score += pontosGameSomosCoop;
		}
		if (pontosGameQuiz) {
			score += pontosGameQuiz;
		}

		setPontos(score);

		async function registerPoints() {
			await actionLog("finish", { score });
		}

		registerPoints();
	}, [coop, coopAtualizada, pdgc, negocios, seloCoop]);

	useEffect(() => {
		showDialog([
			"Conte com a gente para garantir a segurança e privacidade dos seus dados!<br/>Venha conhecer nosso portfólio de serviços.",
		]);

		// if (window.location.hostname === "jornada.coop.br") {
		// 	setSorteioActive(false);
		// }
	}, []);

	return (
		<div id="step18">
			<AudioStep18 />

			<div className="logo flex items-center justify-center">
				<LogoJornada />
			</div>

			<div className="resultados flex flex-col items-center divide-y divide-neon-blue">
				<p className="text-4xl font-bold mb-2 pt-12">Sua pontuação</p>
				<div className="leading-none p-2">
					<div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!coopAtualizada && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!coopAtualizada && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">Participar da Jornada</div>
						<div className="text-4xl font-bold">
							{!!coopAtualizada && (
								<span className="text-green-500">+ 1000</span>
							)}
							{!coopAtualizada && <span className="text-red-500">---</span>}
						</div>
					</div>
					<div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!pontosGameQuiz && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!pontosGameQuiz && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">Acertar o quiz</div>
						<div className="text-4xl font-bold">
							{!!pontosGameQuiz && (
								<span className="text-green-500">+ {pontosGameQuiz}</span>
							)}
							{!pontosGameQuiz && <span className="text-red-500">---</span>}
						</div>
					</div>
					{/* <div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!coop?.length && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!coop?.length && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">
							Participar do{" "}
							{categoria === "identidade" ? "Identidade" : "Desempenho"}{" "}
						</div>
						<div className="text-4xl font-bold">
							{!!coop?.length && <span className="text-green-500">+ 1000</span>}
							{!coop?.length && <span className="text-red-500">---</span>}
						</div>
					</div> */}
					{/* <div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!pontosGameDesempenho && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!pontosGameDesempenho && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">Moedas no game</div>
						<div className="text-4xl font-bold">
							{!!pontosGameDesempenho && (
								<span className="text-green-500">+ {pontosGameDesempenho}</span>
							)}
							{!pontosGameDesempenho && (
								<span className="text-red-500">---</span>
							)}
						</div>
					</div> */}
					{/* <div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!pdgc?.length && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!pdgc?.length && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">Participar do Gov. e Gestão</div>
						<div className="text-4xl font-bold">
							{!!pdgc?.length && <span className="text-green-500">+ 1000</span>}
							{!pdgc?.length && <span className="text-red-500">---</span>}
						</div>
					</div> */}
					<div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!pontosGameGovernanca && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!pontosGameGovernanca && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">
							Eliminar as Brechas de Segurança
						</div>
						<div className="text-4xl font-bold">
							{!!pontosGameGovernanca && (
								<span className="text-green-500">+ {pontosGameGovernanca}</span>
							)}
							{!pontosGameGovernanca && (
								<span className="text-red-500">---</span>
							)}
						</div>
					</div>
					{/* <div className="py-3 flex flex-row space-x-8 justify-between items-center">
						{!!negocios?.length && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!negocios?.length && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">NegóciosCoop</div>
						<div className="text-4xl font-bold">
							{!!negocios?.length && (
								<span className="text-green-500">+ 1000</span>
							)}
							{!negocios?.length && <span className="text-red-500">---</span>}
						</div>
					</div> */}
					{/* <div className="py-2 flex flex-row space-x-8 justify-between items-center">
						{!!pontosGameSomosCoop && (
							<BiCheckCircle size={36} className="text-green-500" />
						)}
						{!pontosGameSomosCoop && (
							<AiFillCloseCircle size={36} className="text-red-500" />
						)}
						<div className="text-4xl flex-1">Montar o quebra-cabeça</div>
						<div className="text-4xl font-bold">
							{!!pontosGameSomosCoop && (
								<span className="text-green-500">+ {pontosGameSomosCoop}</span>
							)}
							{!pontosGameSomosCoop && (
								<span className="text-red-500">---</span>
							)}
						</div>
					</div> */}
					<div className="flex flex-row text-center bg-gray-100 p-5 rounded-xl my-2 leading-none">
						<div className="flex-1 text-center flex flex-col items-center">
							<div className="flex-1 text-left text-3xl">Total de pontos:</div>
							<div>
								<span className="text-6xl font-bold  ">{pontos}</span>{" "}
								<span className="text-2xl">pts.</span>
							</div>
						</div>

						<div className="flex-1 text-center flex flex-col items-center">
							<div className="flex-1 text-left text-3xl">Número da sorte:</div>
							<span className="text-5xl font-bold text-blue-700 border border-gray-600 border-dashed rounded-full leading-none px-6 py-2">
								{user?.extra.lottery}
							</span>
						</div>
					</div>
				</div>

				{/* {pontos >= 3501 && (
					<div className="pt-2 flex justify-center">
						<div className="text-center bg-gray-100 p-5 px-6 leading-none rounded-xl my-2 flex flex-col items-center">
							<div className="flex-1 text-left text-3xl">
								Seu número da sorte:
							</div>
							<span className="text-5xl mt-4 font-bold ">
								{user?.extra.lottery}
							</span>
						</div>
					</div>
				)} */}
			</div>

			{/* <div className="text text-white text-5xl text-center">
				<p>E aí, o que achou da</p>
				<h1 className="font-bold text-8xl">Jornada Coop?</h1>
				<p className="text-4xl mt-5">Isso é só um pouco do que o Sistema OCB<br />tem para oferecer para a sua
					coop!</p>
			</div> */}

			{/* <div className="text-2 text-white text-4xl text-center">
				<h1>Conheça nosso portfolio:</h1>
				{renderQRCode()}
			</div> */}

			{/* <div className="eliza">
				<Eliza name="shipHappy" className=" animate-fly" />
			</div> */}

			<Eliza name="home" className="eliza" />

			{/* <Shape name="white" className="shape" /> */}
		</div>
	);
}
