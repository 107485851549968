import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "components/ui/Button";
import { inputClass } from "styles";
import { useNavigate } from "react-router-dom";
import { SignUpCredentials, useAuth } from "context/AuthContext";
import { LoadingModal } from "components/modal/LoadingModal";
import Keyboard, { KeyboardHandlerEvent } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { BiEdit } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import "../styles.scss";
import { toast } from "react-toastify";
import Eliza from "components/ui/Eliza";
import AudioLogin1 from "components/audio/AudioLogin1";
import AudioLogin2 from "components/audio/AudioLogin2";
import AudioLogin3 from "components/audio/AudioLogin3";

import { cnpjValidation, validateEmail } from "utils/helpers";
import {
	fetchDataCredito,
	fetchDataSaude,
	fetchDataDemais,
	fetchDataDemaisIdentidade,
	fetchDataNegociosCoop,
	fetchDataSouCoop,
	fetchDataGestao,
	fetchDataCoopsRegulares,
	searchCooperado,
} from "services/jornada.service";
import { formatCNPJ } from "services/utils";

const CNPJ_REGEX = RegExp("/^d{2}.d{3}.d{3}/d{4}-d{2}$/");

type LoginFormProps = {};

interface IFormInputs {
	email?: string;
	cnpj?: string;
	phone?: string;
	name?: string;
	search?: string;
}

const registerSchema = yup.object({
	cnpj: yup.string().required("Nome de empresa obrigatório"),
	email: yup.string().email("Email inválido").required("Email obrigatório"),
	name: yup.string().required("Você não digitou seu nome"),
	phone: yup.string().required("Você não digitou seu telefone para contato"),
});

const defaultKeyboardLayout = {
	default: [`1 2 3`, `4 5 6`, `7 8 9`, `0 {backspace}`],
	email: [
		"1 2 3 4 5 6 7 8 9 0 - _ {backspace}",
		"q w e r t y u i o p /",
		"a s d f g h j k l",
		"z x c v b n m .",
		"@ .com {space} .coop .br",
	],
};

const defaultCNPJOptions: any[] = [
	{ value: "08686987000151", label: "08.686.987/0001-51" },
];

export default function LoginForm(props: LoginFormProps) {
	// const { user, login, signIn, signOut } = useContext(AuthContext);
	const navigate = useNavigate();

	const { user, setRegisterUser, login, signUp } = useAuth();
	const [loading, setLoading] = useState(false);
	const [formStep, setFormStep] = useState(0);
	const [keyboardLayout, setKeyboardLayout] = useState("default");
	const [confirmLgpd, setConfirmLgpd] = useState(false);
	const [allowNewsletter, setAllowNewsletter] = useState(true);
	const keyboardRef = useRef(null) as MutableRefObject<KeyboardHandlerEvent>;
	const inputMaskRef = useRef(null) as MutableRefObject<any>;

	const [isShow, setIsShow] = useState(false);
	const [isStepValid, setIsStepValid] = useState(false);
	const [cnpjOptions, setCnpjOptions] = useState(defaultCNPJOptions);

	const [searchResults, setSearchResults] = useState<any[]>([]);

	const {
		control,
		register,
		handleSubmit,
		clearErrors,
		setError,
		setValue,
		getValues,
		formState: { errors },
		watch,
	} = useForm<IFormInputs>({
		resolver: yupResolver(registerSchema),
		reValidateMode: "onChange",
	});

	const onRegister = async (data: IFormInputs) => {
		console.log("onRegister", data);

		clearErrors();
		setLoading(true);

		const payload = {
			...data,
			newsletter: allowNewsletter,
		};

		try {
			const result: any = await signUp(payload as SignUpCredentials);
			await login({ username: `${result._id}`, password: `${payload.cnpj}` });

			setTimeout(() => {
				navigate("/passo/1");
			}, 1000);

			// navigate("/passo/1");
			// setRegisterUser(data);
		} catch (err: any) {
			// setError("name", { message: err });

			setLoading(false);

			toast(
				`Dados inválidos! Por favor revise o CNPJ, e-mail e nome informados.`,
				{
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);

			//@todo tratar NOME já existentes
		}
	};

	function nextStep() {
		clearErrors();

		// console.log(getValues());

		if (formStep === 0) {
			const name = getValues("name");
			if (!name || name.length < 3) {
				setError("name", { message: "Nome inválido" });
				return;
			}
		} else if (formStep === 1) {
			const email = getValues("email") || "";
			if (!validateEmail(email)) {
				setError("email", { message: "Email inválido" });
				return;
			}
		} else if (formStep === 2) {
			const phone = getValues("phone");
			if (!phone || phone.length < 3) {
				setError("phone", { message: "Telefone para contato inválido" });
				return;
			}
		} else if (formStep === 0) {
			const cnpj = getValues("cnpj");
			if (!cnpj || cnpj.length < 3) {
				setError("cnpj", { message: "Nome de empresa inválido" });
				return;
			}
		}

		if (formStep === 4) {
			setLoading(true);
		} else {
			setFormStep(formStep + 1);
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
		}
	}

	function backStep() {
		if (formStep === 0) {
			navigate("../");
		} else {
			setFormStep(formStep - 1);
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
		}
	}

	function showLgpd() {
		setFormStep(5);
	}

	if (loading) {
		return <LoadingModal />;
	}

	return (
		<>
			<div className="eliza animate">
				<Eliza
					name={formStep === 1 ? "shipHappy" : "ship"}
					className={formStep === 1 ? "eliza-right" : "eliza-left"}
				/>
			</div>

			<div className="z-20 relative flex-1 flex flex-row items-center justify-center pl-20">
				<div
					className={`relative my-16`}
					style={{
						width: "1000px",
						minHeight: "600px",
					}}
				>
					<button
						onClick={() => backStep()}
						className="bg-white rounded-full w-12 h-12 shadow absolute -top-4 -left-4 flex items-center justify-center"
					>
						<FaArrowLeft size={24} />
					</button>

					<form
						onSubmit={handleSubmit(onRegister)}
						className="flex flex-col justify-center text-xl font-bold space-y-3 w-full h-full bg-emerald-500 p-12 px-24 rounded-xl"
					>
						<div className="space-y-8">
							{formStep === 0 && (
								<label
									htmlFor="name"
									className="block text-white font-bold text-4xl"
								>
									Vamos começar?
									<br />
									<br />
									Ao final da nossa jornada, você automaticamente estará
									concorrendo a brindes que iremos sortear para que você não se
									esqueça dessa experiência
									<br />
									<br />
									Como você gostaria de ser chamado?
								</label>
							)}

							{formStep === 1 && (
								<label
									htmlFor="email"
									className="block text-white font-bold text-4xl"
								>
									Digite o seu e-mail corporativo.
								</label>
							)}

							{formStep === 2 && (
								<label
									htmlFor="phone"
									className="block text-white font-bold text-4xl"
								>
									Qual é o seu telefone para contato (Whatsapp)?
								</label>
							)}

							{formStep === 3 && (
								<label
									htmlFor="cnpj"
									className="block text-white font-bold text-4xl"
								>
									Qual é o nome da empresa em que trabalha?
								</label>
							)}

							{formStep === 4 && (
								<p className="block text-white font-bold text-4xl">
									Por favor, confirme seus dados
								</p>
							)}

							{formStep === 5 && (
								<p className="block text-white font-bold text-4xl mt-4">
									Termos de Uso e Privacidade
								</p>
							)}

							{formStep === 0 && (
								<div>
									<input
										id="name"
										type="text"
										{...register("name")}
										className={`${inputClass}`}
										placeholder="Digite aqui seu nome"
									/>
									{errors.name?.message && (
										<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
											{errors.name?.message}
										</p>
									)}
								</div>
							)}

							{formStep === 1 && (
								<div>
									<input
										id="email"
										type="email"
										{...register("email")}
										className={`${inputClass}`}
										placeholder="Digite aqui seu email"
									/>
									{errors.email?.message && (
										<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
											{errors.email?.message}
										</p>
									)}
								</div>
							)}

							{formStep === 2 && (
								<div>
									<input
										id="phone"
										type="phone"
										{...register("phone")}
										className={`${inputClass}`}
										placeholder="Digite aqui seu telefone para contato"
									/>
									{errors.phone?.message && (
										<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
											{errors.phone?.message}
										</p>
									)}
								</div>
							)}

							{formStep === 3 && (
								<div>
									<input
										id="cnpj"
										type="cnpj"
										{...register("cnpj")}
										className={`${inputClass}`}
										placeholder="Digite aqui o nome da empresa"
									/>
									{errors.cnpj?.message && (
										<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
											{errors.cnpj?.message}
										</p>
									)}
								</div>
							)}

							{/* {formStep < 3 && (
								<Keyboard
									disableButtonHold={true}
									layout={defaultKeyboardLayout}
									layoutName={keyboardLayout}
									buttonTheme={[
										{
											class: "hg-space",
											buttons: "{space}",
										},
									]}
									onChange={onChange}
									onKeyPress={onKeyPress}
									inputName="keyboard"
									keyboardRef={(r) => (keyboardRef.current = r)}
								/>
							)} */}

							{formStep === 4 && (
								<div>
									<div className="text-white space-y-6 text-2xl">
										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-3xl">NOME:</p>
												{getValues().name}
											</div>

											<Button color="light" onClick={() => setFormStep(0)}>
												<div className="flex flex-row items-center px-2">
													<span>ALTERAR</span>{" "}
													<BiEdit size={24} className="ml-2" />
												</div>
											</Button>
										</div>

										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-3xl">E-MAIL:</p>
												{getValues().email}
											</div>

											<Button color="light" onClick={() => setFormStep(1)}>
												<div className="flex flex-row items-center px-2">
													<span>ALTERAR</span>{" "}
													<BiEdit size={24} className="ml-2" />
												</div>
											</Button>
										</div>

										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-3xl">TELEFONE PARA CONTATO:</p>
												{getValues().phone}
											</div>

											<Button color="light" onClick={() => setFormStep(2)}>
												<div className="flex flex-row items-center px-2">
													<span>ALTERAR</span>{" "}
													<BiEdit size={24} className="ml-2" />
												</div>
											</Button>
										</div>

										<div className="flex flex-row items-center justify-between">
											<div>
												<p className="text-3xl">EMPRESA:</p>
												{getValues().cnpj}
											</div>
											<Button color="light" onClick={() => setFormStep(3)}>
												<div className="flex flex-row items-center px-2">
													<span>ALTERAR</span>{" "}
													<BiEdit size={24} className="ml-2" />
												</div>
											</Button>
										</div>
									</div>

									<div className="text-white mt-10">
										<input
											id="agreement-lgdp"
											name="agreement-lgdp"
											type="checkbox"
											className="mr-3 w-8 h-8 border border-blue-800"
											onChange={(e) => setConfirmLgpd(e.target.checked)}
										/>{" "}
										<label htmlFor="agreement-lgdp">
											Li e estou de acordo com os tratamentos dos dados pessoais
											conforme indicado no{" "}
											<span
												onClick={() => showLgpd()}
												className="text-blue-900 underline"
											>
												Aviso de Privacidade
											</span>{" "}
											da “Jornada LGPDNOW;
										</label>
									</div>

									<div className="text-white mt-6">
										<input
											id="agreement-newsletter"
											name="agreement-newsletter"
											type="checkbox"
											className="mr-3 w-8 h-8 border border-blue-800"
											defaultChecked={true}
											onClick={(e) => {
												const checked: HTMLInputElement = e.currentTarget;
												setAllowNewsletter(checked.checked);
											}}
										/>{" "}
										<label htmlFor="agreement-newsletter">
											Desejo receber comunicações sobre a LGPDNOW por e-mail.
										</label>
									</div>
								</div>
							)}

							{formStep === 5 && (
								<div className="flex h-[600px] flex-col">
									<div className="font-normal bg-white space-y-5 p-5 flex-1 overflow-y-scroll">
										<p>
											<strong>AVISO DE PRIVACIDADE</strong>
										</p>
										<p>
											<strong>Identificação</strong>
										</p>
										<p>
											O&nbsp;<strong>SISTEMA OCB&nbsp;</strong>composto
											pela&nbsp;
											<strong>
												ORGANIZAÇÃO DAS COOPERATIVAS BRASILEIRAS – OCB NACIONAL,
											</strong>{" "}
											inscrita no CNPJ sob nº&nbsp;63.057.822/0001-29,
											<strong>
												{" "}
												SERVIÇO NACIONAL DE APRENDIZAGEM DO COOPERATIVISMO –
												SESCOOP,
											</strong>{" "}
											inscrito no CNPJ sob nº&nbsp;03.087.543/0001-86&nbsp;e
											pela
											<strong>
												{" "}
												CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS - CNCOOP,&nbsp;
											</strong>
											inscrita no CNPJ sob nº&nbsp;07.572.853/0001-47,
											estabelecido&nbsp;no Setor de Autarquias Sul, Quadra 04,
											Bloco I, Ed. Casa do Cooperativismo, Brasília/DF, é o
											responsável por definir os motivos pelos quais os seus
											dados pessoais serão utilizados.
										</p>
										<p>
											<strong>Dados utilizados e para quais finalidades</strong>
										</p>
										<p>
											Não coletaremos dados pessoais desnecessários e
											utilizaremos os dados pessoais conforme especificado no
											resumo abaixo:
										</p>
										<p>Dados Pessoais Utilizados: Nome e e-mail;</p>
										<p>
											Finalidades: 1) Possibilitar o acesso na plataforma “
											<strong>Jornada Coop</strong>”
											<i>
												; 2) Identificar se a cooperativa do usuário utiliza os
												produtos/serviços do Sistema OCB; 3) Emitir cupom para
												sorteio de brinde; 4) Enviar o cupom emitido ao usuário;
												5) Contatar os usuários acerca da “Jornada Coop” e/ou
												sorteio; 6) Enviar comunicações oficiais relacionadas
												com o Sistema OCB.
											</i>
										</p>
										<p>
											<strong>
												Forma e duração da utilização dos seus dados
											</strong>
										</p>
										<p>
											Os dados pessoais indicados neste Aviso serão coletados
											através do formulário digital disponibilizado no
											site&nbsp;
											<a href="http://www.jornada.coop.br">
												www.jornada.coop.br
											</a>
											. Eles serão utilizados para gerar e enviar cupom para que
											o usuário participe de sorteios promovidos por nós. Além
											disso, caso você opte por receber nossas comunicações
											oficiais, enviaremos e-mails divulgando nossas iniciativas
											em prol do cooperativismo.
										</p>
										<p>
											Manteremos os dados pessoais durante o período necessário
											para o atendimento das finalidades indicadas neste Aviso,
											sendo que após, descartaremos os dados pessoais coletados,
											exceto aqueles que a conservação é necessária para atender
											obrigações legais e/ou para possibilitar o exercício de
											direitos em processo judicial, administrativo e/ou
											arbitral. Caso você indique que deseja receber nossas
											comunicações, manteremos seus dados enquanto você não
											efetuar o seu descadastramento de nosso mailing.
										</p>
										<p>
											<strong>
												Sobre o compartilhamento dos dados pessoais
											</strong>
										</p>
										<p>
											Os dados pessoais coletados serão compartilhados apenas
											com as pessoas essenciais ao desenvolvimento da{" "}
											<i>atividade</i>, ou seja <strong>(i)</strong> com a
											empresa responsável pelo desenvolvimento da gamificação e
											armazenamento dos dados coletados; <strong>(ii)</strong>{" "}
											com o provedor de serviço de correio eletrônico (e-mail) e
											e-mail marketing para envio de comunicações oficiais; e{" "}
											<strong>(iii)</strong> com os colaboradores dos nossos
											setores envolvidos no desenvolvimento da iniciativa.
										</p>
										<p>
											Os nossos colaboradores e parceiros estão contratualmente
											obrigados a adotar padrões de segurança no uso dos dados
											pessoais de acordo com a Lei Geral de Proteção de Dados
											Pessoais (LGPD).&nbsp;
										</p>
										<p>
											<strong>Transferência internacional de dados</strong>
										</p>
										<p>
											Informamos que os dados pessoais inseridos no formulário
											serão enviados e armazenados no datacenter da
											subcontratada Digital Ocean que está localizado em Nova
											York nos Estados Unidos da América. Desta forma, ao
											aceitar os termos deste aviso, você consente com a
											transferência internacional de suas informações. No
											entanto, você pode a qualquer tempo revogar este
											consentimento, hipótese em que removeremos os seus dados
											do datacenter. Porém, isso impossibilitará a sua
											participação na “<strong>Jornada Coop</strong>”.
										</p>
										<p>
											<strong>Direitos dos titulares</strong>
										</p>
										<p>
											A Lei Geral de Proteção de Dados Pessoais (LGPD) garante
											uma série de direitos sobre os dados pessoais. Eles podem
											ser exercidos encaminhando uma mensagem para&nbsp;
											<a href="mailto:privacidade@ocb.coop.br">
												<strong>privacidade@ocb.coop.br</strong>
											</a>
											<strong>.</strong>
										</p>
										<p>
											De acordo com a lei, é possível: a) confirmar a existência
											de uso dos dados pessoais; b) acessar os dados pessoais
											utilizados, caso eles estejam sendo usados; c) corrigir
											dados pessoais incompletos, incorretos ou desatualizados;
											d) anonimizar (impedir a identificação através do dado
											pessoal), bloquear ou eliminar os dados desnecessários,
											excessivos ou utilizados em desacordo com a lei; e) obter
											informações sobre o compartilhamento dos dados com
											terceiros; f) se opor aos tratamentos de dados pessoais
											realizados; g) revogar o consentimento previamente
											concedido para tratamento de seus dados; e/ou h) nos casos
											em que não atendermos adequadamente suas solicitações,
											registrar reclamação perante a Autoridade Nacional de
											Proteção de Dados – ANPD.&nbsp;
										</p>
										<p>
											Se recebermos uma solicitação sua para exercer qualquer um
											dos direitos acima,&nbsp;podemos pedir que você confirme
											sua identidade antes&nbsp;de atendermos a solicitação, com
											o objetivo&nbsp;de garantir que seus dados se
											mantenham&nbsp;protegidos em segurança.
										</p>
									</div>
								</div>
							)}

							<div className="mt-2 mx-auto w-2/3 pt-3">
								{formStep < 4 && (
									<Button
										type="button"
										className="w-full py-4 text-3xl disabled:opacity-50"
										onClick={() => nextStep()}
									>
										Prosseguir
									</Button>
								)}
								{formStep === 4 && (
									<Button
										type="submit"
										disabled={!confirmLgpd}
										className="w-full disabled:opacity-50 py-4 text-2xl"
									>
										Estou de acordo com os termos
									</Button>
								)}
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
