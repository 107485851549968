import AudioStep1Nao from "components/audio/AudioStep1Nao";
import AudioStep1Sim from "components/audio/AudioStep1Sim";
import Decoration from "components/ui/Decoration";
import Eliza from "components/ui/Eliza";
import Fireworks from "components/ui/Fireworks";
import Shape from "components/ui/Shape";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { useEffect } from "react";

import "./styles.scss";

const logo = require("assets/images/logo-sou-coop.png");
const confetti = require("assets/animation/confetti.json");

export default function Step1() {
	const { coop, coopInfo, coopAtualizada } = useCoop();
	const { showDialog, closeDialog } = useDialog();

	useEffect(() => {
		return () => {
			document.querySelector("body")?.classList.remove("danger");
			document.querySelector("body")?.classList.remove("soucoop");
		};
	}, []);

	useEffect(() => {
		if (!coopInfo) return;

		document.querySelector("body")?.classList.add("soucoop");

		if (!coopAtualizada) {
			// document.querySelector("body")?.classList.add("danger");
			// document.querySelector("body")?.classList.remove("soucoop");

			showDialog(
				`Poxa, que pena! Vi que a ${coopInfo?.nome_fantasia} não está com os dados atualizados na nossa plataforma Sou.Coop!`
			);
		} else {
			// document.querySelector("body")?.classList.remove("danger");

			showDialog(
				`Parabéns! ${coopInfo?.name}, seus dados estão seguros e atualizados. Você ficará por dentro das novidades sobre o mundo da LGPD e Cibersegurança!`
			);
		}
	}, [coopAtualizada, coopInfo]);

	if (!coop && !coopInfo) return null;

	if (!coopAtualizada) {
		return (
			<>
				{/* <AudioStep1Nao /> */}
				<div id="step1">
					<div className="absolute top-0 bottom-0 right-0 left-0 m-auto w-1/2 h-1/2 flex flex-col items-center justify-center">
						<h1 className="text-white text-9xl">Que pena!</h1>
						<h1 className="text-white text-6xl mb-6 text-center">
							{coopInfo?.nome_fantasia}
						</h1>
						<h1 className="flex flex-row items-center border-4 border-blue-500 py-2 px-5 font-bold text-white text-4xl">
							dados desatualizados no{" "}
							<img src={logo} className="w-36 mt-2 ml-5" />
						</h1>
					</div>
					<Eliza name="sad" className="eliza-sad animate animate-wiggle" />
				</div>
			</>
		);
	}

	return (
		<>
			{/* <AudioStep1Sim /> */}
			<div id="step1">
				<Fireworks />
				<div className="absolute top-0 bottom-0 right-0 left-0 m-auto w-1/2 h-1/2 flex flex-col items-center justify-center">
					<h1 className="text-white text-9xl">Parabéns</h1>
					<p className="text-white text-6xl mb-6 text-center">
						{coopInfo?.nome_fantasia}
					</p>
					<h1 className="flex flex-row items-center border-4 border-neon-blue py-2 px-5 font-bold text-white text-4xl">
						Seus dados estão seguros aqui!
					</h1>
				</div>
				<Eliza name="stars" className="eliza animate animate-wiggle" />

				{/* <Shape name="white" className="shape" /> */}

				<Decoration
					name="hands"
					className="hands duration-500 animate-in slide-in-from-bottom"
				/>
			</div>
		</>
	);
}
