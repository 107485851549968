import Eliza from "components/ui/Eliza";

import "./styles.scss";
import LogoOCB from "components/ui/LogoOCB";
import Button from "components/ui/Button";
import { useEffect, useState } from "react";
import LotteryDisplay from "./display";
import { getWinners } from "services/lottery.service";
import { AiOutlineReload } from "react-icons/ai";

export default function Lottery() {
	const [winners, setWinners] = useState<any[]>();
	const [canRetry, setCanRetry] = useState(false);
	const [loading, setLoading] = useState(false);

	async function handleRunLottery() {
		try {
			setCanRetry(false);
			setLoading(true);
			const params = new URLSearchParams(window.location.search);
			const isRandom: boolean = !!params.get("random");
			const winnersList = await getWinners("Dm53epV", isRandom);
			setWinners(winnersList);
			setLoading(false);
		} catch (e) {}
	}

	useEffect(() => {
		if (winners) {
			setTimeout(() => {
				setCanRetry(true);
			}, 2000);
		}
	}, [winners]);

	return (
		<div
			id="lottery"
			className="relative w-full h-full flex items-center justify-center"
		>
			<div className="relative w-[1000px] bg-white rounded-3xl p-16 border flex flex-col gap-10 text-center font-bold ml-[400px]">
				<h1 className="text-6xl">SORTEIO DO BRINDE LGPDNOW</h1>
				{winners && !loading ? (
					<div className="space-y-4">
						{winners.map((winner: any) => (
							<div
								key={winner._id}
								className="flex overflow-hidden rounded-full border-emerald-500 border-2"
							>
								<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
									<LotteryDisplay
										finalName={winner.name.toUpperCase().split(" ")[0]}
										wordLength={8}
										waitTime={2000}
									/>
								</div>
								<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
									<LotteryDisplay
										finalName={winner.extra.lottery}
										wordLength={5}
										waitTime={2000}
									/>
								</div>
							</div>
						))}
						{/* <div className="text-8xl flex items-center justify-center p-10 rounded-full border-emerald-500 border-2">
							<span className="leading-none text-emerald-500">
								<LotteryDisplay
									finalName={winner.extra.lottery}
									wordLength={6}
									waitTime={2000}
								/>
							</span>
						</div> */}
					</div>
				) : null}
				{loading ? (
					<div className="space-y-4">
						<div className="flex overflow-hidden rounded-full border-emerald-500 border-2">
							<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={8}
									waitTime={10000}
								/>
							</div>
							<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={5}
									waitTime={10000}
								/>
							</div>
						</div>
						<div className="flex overflow-hidden rounded-full border-emerald-500 border-2">
							<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={8}
									waitTime={10000}
								/>
							</div>
							<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={5}
									waitTime={10000}
								/>
							</div>
						</div>
						<div className="flex overflow-hidden rounded-full border-emerald-500 border-2">
							<div className="text-6xl  leading-none w-[500px] text-left text-white bg-emerald-500 flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={8}
									waitTime={10000}
								/>
							</div>
							<div className="leading-none text-7xl  text-center w-[350px] flex items-center p-10">
								<LotteryDisplay
									finalName={"CARREGANDO"}
									wordLength={5}
									waitTime={10000}
								/>
							</div>
						</div>
					</div>
				) : null}
				{!winners && !loading ? (
					<Button onClick={handleRunLottery} className="text-4xl p-10">
						SORTEAR UM GANHADOR
					</Button>
				) : null}
				{canRetry && !loading ? (
					<Button
						onClick={handleRunLottery}
						className="text-4xl p-5 absolute -top-8 -right-8 ring ring-white shadow-xl"
					>
						<AiOutlineReload size={36} />
					</Button>
				) : null}
			</div>
			<Eliza name="home" className="eliza animate-in slide-in-from-left-20" />
			<LogoOCB className="logo-ocb" />
		</div>
	);
}
