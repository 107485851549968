import AudioStep2 from "components/audio/AudioStep2";
import Curve from "components/ui/Curve";
import Eliza from "components/ui/Eliza";
import Shape from "components/ui/Shape";
import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";
import { useEffect, useState } from "react";

import "./styles.scss";
import Button from "components/ui/Button";

export interface Step2GameProps {
	onFinish: (answer: string) => void;
}

export default function Step2Game({ onFinish }: Step2GameProps) {
	const { coopInfo } = useCoop();
	const [dataFundacao, setDataFundacao] = useState(0);
	const [tempoDeVida, setTempoDeVida] = useState(-1);
	const { showDialog } = useDialog();

	useEffect(() => {
		if (!coopInfo) return;
		try {
			const dataFundacao = new Date(
				coopInfo.constituicao_fundacao
			).getFullYear();
			setDataFundacao(dataFundacao);
		} catch (e) {
			console.log(e);
			setDataFundacao(0);
		}
	}, [coopInfo]);

	useEffect(() => {
		if (!dataFundacao) return;
		// setTempoDeVida(new Date().getFullYear() - dataFundacao);
		// // setTempoDeVida(0);
	}, [dataFundacao]);

	// useEffect(() => {
	// 	if (tempoDeVida < 0) return;
	// 	showDialog(
	// 		`Também estou vendo aqui que a sua coop foi fundada em ${dataFundacao}. Já são ${tempoDeVida} anos de uma história de cooperação e sucesso! Você sabia que a média nacional de vida de uma coop no Brasil é de 20 anos?`
	// 	);
	// }, [dataFundacao, tempoDeVida]);

	useEffect(() => {
		document.querySelector("body")?.classList.add("soucoop");
		return () => {
			document.querySelector("body")?.classList.remove("soucoop");
		};
	}, []);

	if (!coopInfo || !dataFundacao) return null;

	const renderButtons = () => {
		const buttons = [
			<Button
				key="a"
				onClick={() => onFinish("timeline_a")}
				type="button"
				className="text-6xl bg-emerald-500"
			>
				{dataFundacao - 3 - Math.round(Math.random() * 3)}
			</Button>,
			<Button
				key="b"
				onClick={() => onFinish("timeline_b")}
				type="button"
				className="text-6xl bg-emerald-500"
			>
				{dataFundacao}
			</Button>,
			<Button
				key="c"
				onClick={() => onFinish("timeline_c")}
				type="button"
				className="text-6xl bg-emerald-500"
			>
				{new Date().getFullYear() - dataFundacao > 3
					? dataFundacao + 1 + Math.round(Math.random() * 2)
					: dataFundacao - 1 - Math.round(Math.random() * 2)}
			</Button>,
		];

		//render the buttons in a random order
		return buttons.sort(() => Math.random() - 0.5);
	};

	return (
		<>
			<div id="step2">
				<div className="absolute animate-in zoom-in right-[15%] top-[200px] w-[600px] h-[600px] bg-white shadow-xl p-3 rounded-xl text-xl flex flex-col items-center justify-center">
					<h1 className="text-5xl text-center">
						Em que ano a LGPD foi sancionada?
					</h1>

					<div className="w-full mt-4 p-6 flex flex-col gap-6">
						{renderButtons()}
						{/* <Button
							onClick={() => onFinish("timeline_a")}
							type="button"
							className="text-6xl"
						>
							{dataFundacao - 3 - Math.round(Math.random() * 23)}
						</Button>
						<Button
							onClick={() => onFinish("timeline_b")}
							type="button"
							className="text-6xl"
						>
							{dataFundacao}
						</Button>
						<Button
							onClick={() => onFinish("timeline_c")}
							type="button"
							className="text-6xl"
						>
							{new Date().getFullYear() - dataFundacao > 3
								? dataFundacao + 1 + Math.round(Math.random() * 2)
								: dataFundacao - Math.round(Math.random() * 3)}
						</Button> */}
					</div>
				</div>
				<Eliza name="left" className="eliza animate-in slide-in-from-bottom" />
			</div>
		</>
	);
}
