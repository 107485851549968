import { api } from "./api";

type LoginRequestData = {
	username: string;
	password: string;
	apiKey?: string;
	grant_type?: string;
};

export function loginRequest(data: LoginRequestData) {
	data["apiKey"] = process.env.REACT_APP_APIKEY;
	data["grant_type"] = "password";

	return api
		.post("/auth/token", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			// console.error(error.response.data.message);
			throw error.response.data;
		});
}

export function fetchUser() {
	return api
		.get("/player/me/status")
		.then((response) => {
			const user = response.data;
			if (user) {
				return user;
			} else {
				document.dispatchEvent(new CustomEvent("signout"));
			}
		})
		.catch((error) => {
			console.log(error);
		});
}

export function registerUserRequest(user: any) {
	return api
		.put("/database/registration__c", user, {
			headers: {
				Authorization:
					"Basic " +
					btoa(
						`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_PUBLIC_SECRETKEY}`
					),
			},
		})
		.then((response) => {
			const result = response.data;
			// return result;
			if (result.status === 200) {
				return result;
			} else {
				// throw(result.message);
				return result;
			}
		})
		.catch((error) => {
			throw error;
		});

	// const player = {...user, extra: {cpf: user.cpf}};

	// return api
	// 	.post("/player", player, {
	// 		headers: {
	// 			Authorization:
	// 				"Basic " +
	// 				btoa(
	// 					`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`
	// 				),
	// 		},
	// 	})
	// 	.then((response) => {
	// 		const result = response.data;
	// 		return result;
	// 	})
	// 	.catch((error) => {
	// 		console.log(error);
	// 	});
}

export async function recoverPasswordRequest(data: LoginRequestData) {
	data["apiKey"] = process.env.REACT_APP_APIKEY;
	data["grant_type"] = "password";

	try {
		const response = await api.post("/auth/token", data);
		return response.data.access_token;
	} catch (error) {
		console.log(error);
	}
}
